import { IDynamicContentPages, IFilterRequest, ILocationFilterState } from "../../classes/dynamic-listing";
import { ActionType } from "../../classes/dynamic-listing-actions";

export function removeAllFilters() {
    return {
        type: ActionType.RemoveAllFilters,
        value: ""
    }
}

export function updateFilterRequest(filterReq: IFilterRequest) {
    return {
        type: ActionType.UpdateFilterRequest,
        value: filterReq
    }
}

export function updatePagesPostLocationFilter(contentPages: Array<IDynamicContentPages>) {
    return {
        type: ActionType.UpdatePagesPostLocationFilter,
        value: contentPages
    }
}

export function updateLocationState(locationState: ILocationFilterState) {
    return {
        type: ActionType.UpdateLocationState,
        value: locationState
    }
}

export function resetLocationState() {
    return {
        type: ActionType.ResetLocationState
    }
}