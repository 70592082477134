import * as React from 'react';
import * as _ from 'lodash';
import { DynamicContentPaginationViewProps } from '../../components/pagination';


class DynamicContentPaginationView extends React.Component<DynamicContentPaginationViewProps, any>{
    constructor(props) {
        super(props);
    }

    onPrevButtonClick() {
        this.props.onPrevious();
        this.scrollUp();
    }

    onPageButtonClick(number) {
        this.props.onPage(number);
        this.scrollUp();
    }

    onNextButtonClick() {
        this.props.onNext();
        this.scrollUp();
    }

    onLoadMore(event) {
        this.props.onLoadMore();
        event.target.closest('[data-dynamic-listing-entry]').scrollIntoView()
    }

    scrollUp() {
        var startReactElement = document.getElementById('dynamic-listing-entry-js');
        startReactElement.scrollIntoView();
    }

    render() {
        return !this.props.pagination.hidePagination ?
            <nav className="pagination-nav space-top-xl" aria-labelledby="paginationLabel" data-number-of-pages={this.props.pageNumbers}>
                <h2 className="visually-hidden" id="paginationLabel">Pagination</h2>
                <div className="pagination-nav-pages-prev">
                    <div className="pagination-nav-back pagination-nav-back-one">
                        <button type="button" className="pagination-nav-arrow pagination-nav-arrow-prev pagination-nav-btn" disabled={this.props.pagination.currentPage == 1 ? true : false} onClick={() => this.onPrevButtonClick()}>
                            <svg className="svg-icon" aria-hidden="true" focusable="false">
                                <use xlinkHref="#icon-chevron-left" />
                            </svg>
                            <span className="visually-hidden">Go to previous page of results.</span>
                        </button>
                    </div>
                </div>

                <ul className="pagination-nav-count pagination-nav-pages-count">
                    {
                        this.props.pageNumbers.map(number => {
                            return (
                                <li className="pagination-nav-count-item" key={number}>
                                    <button className={`pagination-nav-btn ${this.props.pagination.currentPage == number ? "btn-primary" : "btn-secondary"}`}
                                        aria-label={this.props.pagination.currentPage == number ? "Current Page, Page " + number : "Go to Page " + number}
                                        aria-current={this.props.pagination.currentPage == number ? "true" : "false"} onClick={() => this.onPageButtonClick(number)}>
                                        <span>{number}</span>
                                    </button>
                                </li>
                            )
                        })
                    }
                </ul>

                <div className="pagination-nav-pages-next">
                    <div className="pagination-nav-forward pagination-nav-forward-one">
                        <button type="button" className="pagination-nav-arrow pagination-nav-btn" disabled={this.props.pagination.currentPage == this.props.pageNumbers.length || this.props.pageNumbers.length == 0 ? true : false} onClick={() => this.onNextButtonClick()}>
                            <svg aria-hidden="true" focusable="false">
                                <use xlinkHref="#icon-chevron-right" />
                            </svg>
                            <span className="visually-hidden">Go to next page of results.</span>
                        </button>
                    </div>
                </div>
            </nav>
            :
            <>
                {
                    this.props.pagination.resultsToShow !== this.props.pagination.totalResults && this.props.pagination.totalResults !== 0 &&
                    <div className="space-top-lg">
                        <button type="button" className="btn-primary" onClick={(e) => this.onLoadMore(e)}>Load more</button>
                    </div>
                }

            </>
    }
}

export default DynamicContentPaginationView;