import * as React from 'react';
import AppView from '../views/library/dynamic-app.view';
import { IDynamicContentAppState } from '../redux-state';
import { connect } from 'react-redux';

interface AppExternalProps {

}

interface StateToProps {
}

export type AppProps = AppExternalProps & StateToProps;

export class AppComponent extends React.Component<AppProps, any> {
    constructor(props: AppProps) {
        super(props);
    }

    render() {
        return (
            <AppView {...this.props} />
        )
    }
}

function mapStateToProps(state: IDynamicContentAppState, ownProps: AppExternalProps): StateToProps {
    return {
    }
}

export default connect<StateToProps>(mapStateToProps)(AppComponent);
