import * as React from 'react';
import { useState } from 'react';
import { DynamicContentContainerViewProps } from '../../components/container';
import DynamicContentFilterContainerView from '../library/dynamic-filter-container.view';
import DynamicLocationFilterContainerView from '../library/dynamic-location-container.view';
import { defaultDynamicContentState, defaultFilterNames } from '../../classes/dynamic-listing';


const DynamicContentListingAllFiltersView: React.FunctionComponent<DynamicContentContainerViewProps> = props => {
    const clearFilters = props.text.firstFilterTitle !== "" || props.text.secondFilterTitle !== ""
        || props.text.thirdFilterTitle !== "" ? <div className="listing-clear">
            <button className="btn-primary btn-wide" onClick={() => props.clearAllFilters()}>{props.text.clearFiltersText}</button>
    </div>
        : null;
    const filterDropdowns = <ul className={`listing-filters-dropdowns`}>
        {
            props.text.firstFilterTitle !== "" ?
                <DynamicContentFilterContainerView container={props} dropdownList={props.filters.firstFilter} defaultValue={props.text.firstFilterDefaultValue} label={props.text.firstFilterTitle} id={defaultFilterNames.firstFilterName} selectedValue={props.request.selectedFirstFilter} />
                : null
        }
        {
            props.text.secondFilterTitle !== "" ?
                <DynamicContentFilterContainerView container={props} dropdownList={props.filters.secondFilter} defaultValue={props.text.secondFilterDefaultValue} label={props.text.secondFilterTitle} id={defaultFilterNames.secondFilterName} selectedValue={props.request.selectedSecondFilter} />
                : null
        }
        {
            props.text.thirdFilterTitle !== "" ?
                <DynamicContentFilterContainerView container={props} dropdownList={props.filters.thirdFilter} defaultValue={props.text.thirdFilterDefaultValue} label={props.text.thirdFilterTitle} id={defaultFilterNames.thirdFilterName} selectedValue={props.request.selectedThirdFilter} />
                : null
        }
        {
            props.text.locationLabel !== "" ?
                <DynamicLocationFilterContainerView container={props} label={props.text.locationLabel} buttonText={props.text.locationButtonText} placeholder={props.text.locationPlaceholder} locationState={props.locationState} errorMessage={props.text.locationErrorMessage} value="" />
                : null
        }
        {
            props.text.searchAllButtonText.text !== '' && props.text.searchAllButtonText.url.path !== '' &&
            <li className="listing-filters-item-link"><a className="btn-primary" href={props.text.searchAllButtonText.url.path}>{props.text.searchAllButtonText.text}</a></li>
        }

    </ul>;
    // Declare a new state variable, which we'll call "count"
    const [filtersOpen, setFiltersOpen] = useState(false);
    return (
        <>
            <div className="listing-filters-mobile">
                <button type="button" className="listing-filters-toggle btn-secondary btn-wide" aria-expanded={ filtersOpen } onClick={(e) => {
                    setFiltersOpen(!filtersOpen)
                }}>
                    {props.text.filterHeadingMobile}
                    <svg role="presentation" focusable="false" className="listing-filters-toggle-open">
                        <use xlinkHref="#icon-filter"></use>
                    </svg>
                    <svg role="presentation" focusable="false" className="listing-filters-toggle-close">
                        <use xlinkHref="#icon-close"></use>
                    </svg>
                </button>
                <div className="stack-sm">
                    <div aria-hidden={!filtersOpen} className="listing-filters-container">
                        {
                            filterDropdowns
                        }
                        
                    </div>
                    {clearFilters}
                    <p>{props.pagination.populatedResultsMessage}</p>
                </div>
            </div>
            <div className="listing-filters-desktop stack-lg">
                <div className="listing-filters-dropdowns-container">
                    {
                        filterDropdowns
                    }
                    
                </div>
                <div className="listing-results">
                    <p>{props.pagination.populatedResultsMessage}</p>
                    {
                        clearFilters
                    }
                   
                </div>
            </div>
        </>
    )
}

export default DynamicContentListingAllFiltersView;