export function getParameterByName(name) {
    const url = window.location.href;
    return getParameterFromUrl(name, url);
}

export function getParameterFromUrl(name: string, url: string) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}