export interface IFilterRequest {
    selectedFirstFilter: string;
    selectedSecondFilter: string;
    selectedThirdFilter: string;
    filterId: string;
    newSelectedValue: string;
}

export const defaultFilterRequest: IFilterRequest = {
    selectedFirstFilter: "",
    selectedSecondFilter: "",
    selectedThirdFilter: "",
    filterId: "",
    newSelectedValue: ""
}

export interface IPagination {
    currentPage: number;
    totalResults: number;
    maximumNumOfPages: number;
    resultsMessage: string;
    populatedResultsMessage: string;
    hidePagination: boolean;
    resultsToShow: number;
}

export const defaultPagination: IPagination = {
    currentPage: 1,
    totalResults: 0,
    maximumNumOfPages: 12,
    resultsMessage: "",
    populatedResultsMessage: "",
    hidePagination: false,
    resultsToShow: 0
}

export interface ILocationFilterState {
    latitude: number;
    longitude: number;
    dynamicContentPages: Array<IDynamicContentPages>;
    filteredDynamicContentPages: Array<IDynamicContentPages>;
    showError: boolean;
    foundAddress: string;
}

export const defaultLocationFilterRequest: ILocationFilterState = {
    latitude: 0,
    longitude: 0,
    dynamicContentPages: [],
    filteredDynamicContentPages: [],
    showError: false,
    foundAddress: ""
}

export interface IDynamicContentState {
    dynamicContentFilters: IDynamicContentFilters;
    filteredDynamicContentPages: Array<IDynamicContentPages>;
    allDynamicContentPages: Array<IDynamicContentPages>;
    request: IFilterRequest;
    pagination: IPagination;
    locationFilterState: ILocationFilterState;
}

export const defaultDynamicContentState: IDynamicContentState = {
    dynamicContentFilters: null,
    filteredDynamicContentPages: [],
    allDynamicContentPages: [],
    request: defaultFilterRequest,
    pagination: defaultPagination,
    locationFilterState: defaultLocationFilterRequest
};

export interface ISearchButtonURL {
    path: string;
}

export interface ISearchButtonText {
    target: string;
    text: string;
    url: ISearchButtonURL;
}

export interface IDynamicContentText {
    filterHeading: string;
    filterHeadingMobile: string;
    firstFilterTitle: string;
    firstFilterDefaultValue: string;
    firstFilterParentName: string;
    secondFilterTitle: string;
    secondFilterDefaultValue: string;
    secondFilterParentName: string;
    thirdFilterTitle: string;
    thirdFilterDefaultValue: string;
    thirdFilterParentName: string;
    clearFiltersText: string;
    maximumNumberOfPages: number;
    noResultsMessage: string;
    appointmentButtonText: string;
    appointmentButtonPageUrl: string;
    learnMoreButtonText: string;
    locationLabel: string;
    locationPlaceholder: string;
    locationButtonText: string;
    locationErrorMessage: string;
    distanceMessaging: string;
    useMyLocationButtonText: string;
    searchAllButtonText: ISearchButtonText;
}

export const defaultDynamicContentText: IDynamicContentText = {
    filterHeading: "",
    filterHeadingMobile: "",
    firstFilterTitle: "",
    firstFilterDefaultValue: "Any Specialty",
    firstFilterParentName: "",
    secondFilterTitle: "",
    secondFilterDefaultValue: "Any Language",
    secondFilterParentName: "",
    thirdFilterTitle: "",
    thirdFilterDefaultValue: "All Distances",
    thirdFilterParentName: "",
    clearFiltersText: "Clear Filters",
    maximumNumberOfPages: 16,
    noResultsMessage: "",
    appointmentButtonText: "Make an Appointment",
    appointmentButtonPageUrl: "",
    learnMoreButtonText: "Learn More",
    locationLabel: "",
    locationPlaceholder: "",
    locationButtonText: "",
    locationErrorMessage: "",
    distanceMessaging: "",
    useMyLocationButtonText: "",
    searchAllButtonText: {
        target: '',
        text: '',
        url: {
            path: ''
        }
    }
}

export interface IDynamicContentPages {
    firstFilter: Array<string>;
    secondFilter: Array<string>;
    thirdFilter: Array<string>;
    title: string;
    imageUrl: string;
    dynamicContentPageUrl: string;
    description: Array<string>;
    isDoctorPage: boolean;
    hideFirstFilterContent: boolean;
    hideSecondFilterContent: boolean;
    hideThirdFilterContent: boolean;
    locationCoordinates: Array<IGeoLocationCoordinates>;
    distanceFrom: number;
}

export interface IDynamicContentFilters {
    firstFilter: Array<string>;
    secondFilter: Array<string>;
    thirdFilter: Array<string>;
}

export interface IGeoLocationCoordinates {
    latitude: number;
    longitude: number;
}

export interface IFilterNames {
    firstFilterName: string;
    secondFilterName: string;
    thirdFilterName: string;
}

export const defaultFilterNames: IFilterNames = {
    firstFilterName: "firstFilter",
    secondFilterName: "secondFilter",
    thirdFilterName: "thirdFilter"
}

export interface IOption {
    text: string;
    value: string;
}