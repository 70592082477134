import * as React from 'react';
import { DynamicContentContainerViewProps } from '../../components/container';
import { IFilterRequest } from '../../classes/dynamic-listing'

interface ICurrentFilter {
    dropdownList: Array<string>;
    defaultValue: string;
    label: string;
    id: string;
    selectedValue: string;
    container: DynamicContentContainerViewProps
}

const DynamicContentFilterContainerView: React.FunctionComponent<ICurrentFilter> = props => {
    function onChange(evt: React.ChangeEvent<HTMLSelectElement>) {
        const selectedValue = evt.currentTarget.value;
        const selectEl = evt.currentTarget;

        var initialRequest: IFilterRequest = {
            selectedFirstFilter: props.container.request.selectedFirstFilter,
            selectedSecondFilter: props.container.request.selectedSecondFilter,
            selectedThirdFilter: props.container.request.selectedThirdFilter,
            filterId: selectEl.id,
            newSelectedValue: selectedValue
        };

        props.container.updateFilterRequest(initialRequest);
    }

    return (
        <li className="stack-sm listing-filters-item">
            <label htmlFor={props.id}>{props.label}</label>
            <select id={props.id} value={props.selectedValue} onChange={onChange}>
                <option value="">{props.defaultValue}</option>
                {
                    props.dropdownList.map(filter => <option key={`${props.id}_${filter}`} value={filter}>{filter}</option>)
                }
            </select>
        </li>
    )
}

export default DynamicContentFilterContainerView;