import * as React from 'react';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { IPagination } from '../classes/dynamic-listing';
import { IDynamicContentAppState } from '../redux-state';
import { goToPage, nextPage, previousPage, loadMore } from '../redux-state/actions/dynamic-pagination.actions';
import DynamicContentPaginationView from '../views/library/dynamic-listing-pagination.view';

interface StateToProps {
    pagination: IPagination;
    pageNumbers: Array<number>;
}

interface DispatchToProps {
    onNext: () => void;
    onPrevious: () => void;
    onPage: (index: number) => void;
    onLoadMore: () => void;
}

export type DynamicContentPaginationProps = StateToProps & DispatchToProps;
export type DynamicContentPaginationViewProps = StateToProps & DispatchToProps;
export class DynamicContentPaginationBaseContainer extends React.Component<DynamicContentPaginationProps, DynamicContentPaginationViewProps>  {
    constructor(props: DynamicContentPaginationProps) {
        super(props);
    }

    render() {
        return (
            <DynamicContentPaginationView {...this.props} />
        )
    }
}

function mapStateToProps(state: IDynamicContentAppState, ownProps: DynamicContentPaginationProps): StateToProps {
    var pageNumbers = [];
    for (let i = 1; i <= Math.ceil(state.dynamicContentState.filteredDynamicContentPages.length / state.dynamicContentText.maximumNumberOfPages); i++) {
        pageNumbers.push(i);
    }

    return {
        pagination: state.dynamicContentState.pagination,
        pageNumbers: pageNumbers
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchToProps {
    return {
        onNext: () => dispatch(nextPage()),
        onPrevious: () => dispatch(previousPage()),
        onPage: (index: number) => dispatch(goToPage(index) as any),
        onLoadMore: () => dispatch(loadMore()),
    }
}

export default connect<StateToProps, DispatchToProps>(mapStateToProps, mapDispatchToProps)(DynamicContentPaginationBaseContainer);
