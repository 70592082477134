import * as React from 'react';
import { Dispatch } from 'react';
import { connect } from 'react-redux';
import { IDynamicContentFilters, IDynamicContentPages, IFilterRequest, IDynamicContentText, IPagination, ILocationFilterState } from '../classes/dynamic-listing';
import { IDynamicContentAppState } from '../redux-state';
import { removeAllFilters, updateFilterRequest, updateLocationState, resetLocationState, updatePagesPostLocationFilter } from '../redux-state/actions/dynamic-filter.actions';
import DynamicContentListingContainerView from '../views/library/dynamic-listing-container.view';

interface StateToProps {
    filteredDynamicContentPages: Array<IDynamicContentPages>;
    dynamicContentPages: Array<IDynamicContentPages>;
    filters: IDynamicContentFilters;
    text: IDynamicContentText;
    firstIndex: number;
    lastIndex: number;
    request: IFilterRequest;
    pagination: IPagination;
    locationState: ILocationFilterState;
}

interface DispatchToProps {
    updateFilterRequest: (initialRequest: IFilterRequest) => void;
    clearAllFilters: () => void;
    updatePagesPostLocationFilter: (contentPages: Array<IDynamicContentPages>) => void;
    updateLocationState: (locationState: ILocationFilterState) => void;
    resetLocationState: () => void;
}

export type DynamicContentContainerProps = StateToProps & DispatchToProps;
export type DynamicContentContainerViewProps = StateToProps & DispatchToProps;
export class DynamicContentBaseContainer extends React.Component<DynamicContentContainerProps, DynamicContentContainerViewProps>  {
    constructor(props: DynamicContentContainerProps) {
        super(props);
    }

    render() {
        return (
            <DynamicContentListingContainerView {...this.props} />
        )
    }
}

function mapStateToProps(state: IDynamicContentAppState, ownProps: DynamicContentContainerProps): StateToProps {
    var lastIndex = state.dynamicContentState.pagination.currentPage * state.dynamicContentText.maximumNumberOfPages;
    var firstIndex = lastIndex - state.dynamicContentText.maximumNumberOfPages;
    if (state.dynamicContentState.pagination.hidePagination) {
        firstIndex = 0;
        lastIndex = state.dynamicContentState.pagination.resultsToShow;
    }
    return {
        filteredDynamicContentPages: state.dynamicContentState.filteredDynamicContentPages,
        dynamicContentPages: state.dynamicContentState.allDynamicContentPages,
        filters: state.dynamicContentState.dynamicContentFilters,
        text: state.dynamicContentText,
        firstIndex: firstIndex,
        lastIndex: lastIndex,
        request: state.dynamicContentState.request,
        pagination: state.dynamicContentState.pagination,
        locationState: state.dynamicContentState.locationFilterState
    }
}

function mapDispatchToProps(dispatch: Dispatch<any>): DispatchToProps {
    return {
        updateFilterRequest: (initialRequest: IFilterRequest) => dispatch(updateFilterRequest(initialRequest)),
        clearAllFilters: () => dispatch(removeAllFilters()),
        updatePagesPostLocationFilter: (contentPages: Array<IDynamicContentPages>) => dispatch(updatePagesPostLocationFilter(contentPages)),
        updateLocationState: (locationState: ILocationFilterState) => dispatch(updateLocationState(locationState)),
        resetLocationState: () => dispatch(resetLocationState())
    }
}

export default connect<StateToProps, DispatchToProps>(mapStateToProps, mapDispatchToProps)(DynamicContentBaseContainer);
