import { ActionType } from "../../classes/dynamic-listing-actions";
import { IDynamicContentFilters, IDynamicContentPages, IFilterRequest, IDynamicContentText } from '../../classes/dynamic-listing';

export function setFilterRequest(request: IFilterRequest) {
    return {
        type: ActionType.SetFilterRequest,
        value: request
    }
}

export function setDynamicContentObject(dynamicContent: Array<IDynamicContentPages>) {
    return {
        type: ActionType.SetDynamicContentObject,
        value: dynamicContent
    }
}

export function setDynamicContentTextObject(dynamicContentText: IDynamicContentText) {
    return {
        type: ActionType.SetDynamicContentTextObject,
        value: dynamicContentText
    }
}

export function setFilterObject(dynamicContentFilter: IDynamicContentFilters) {
    return {
        type: ActionType.SetFilterObject,
        value: dynamicContentFilter
    }
}
