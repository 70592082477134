import * as React from 'react';
import App from './components/app';
import { createRoot } from 'react-dom/client';
import { setAppBlockStore, appStore } from './redux-state';
import { Provider } from 'react-redux';

const container = document.getElementById('dynamic-listing-entry-js');
if (container) {
    const root = createRoot(container);
    root.render(
        <Provider store={appStore}>
            <App />
        </Provider>
    );
}

const dynamicListingBlocks = Array.prototype.slice.call(document.querySelectorAll('[data-dynamic-listing-entry]'));
if (dynamicListingBlocks.length > 0) {
    dynamicListingBlocks.forEach(dynamicListingBlock => {
        const dynamicListingRoot = createRoot(dynamicListingBlock);
        const dynamicListingUniqueId = dynamicListingBlock.getAttribute('id');
        dynamicListingRoot.render(
            <Provider store={setAppBlockStore(dynamicListingUniqueId)}>
                <App />
            </Provider>
        );
    });
}
