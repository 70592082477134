/**
 * Describes the shape of a single action.
 */
export interface IDynamicContentStateAction {
    type: ActionType;
    value?: any;
}



export class ActionType {

    static get NextPage() {
        return "NextPage";
    }
    static get PreviousPage() {
        return "PreviousPage";
    }
    static get LoadMore() {
        return "LoadMore";
    }
    static get GoToPage() {
        return "GoToPage";
    }
    static get SetFilterRequest() {
        return "SetFilterRequest";
    }
    static get SetDynamicContentObject() {
        return "SetDynamicContentObject";
    }
    static get SetDynamicContentTextObject() {
        return "SetDynamicContentTextObject";
    }
    static get SetFilterObject() {
        return "SetFilterObject";
    }
    static get SetPaginationObject() {
        return "SetPaginationObject";
    }
    static get UpdateFilterRequest() {
        return "UpdateFilterRequest";
    }
    static get RemoveAllFilters() {
        return "RemoveAllFilters";
    }
    static get UpdateDynamicContentPages() {
        return "UpdateDynamicContentPages";
    }
    static get UpdatePagesPostLocationFilter() {
        return "UpdatePagesPostLocationFilter";
    }
    static get UpdateLocationState() {
        return "UpdateLocationState";
    }
    static get ResetLocationState() {
        return "ResetLocationState";
    }
}