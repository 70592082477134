import { IPagination } from "../../classes/dynamic-listing";
import { ActionType } from "../../classes/dynamic-listing-actions";

export function nextPage() {
    return {
        type: ActionType.NextPage,
        value: ""
    }
}

export function previousPage() {
    return {
        type: ActionType.PreviousPage,
        value: ""
    }
}

export function loadMore() {
    return {
        type: ActionType.LoadMore,
        value: ""
    }
}

export function goToPage(index: number) {
    return {
        type: ActionType.GoToPage,
        value: index
    }
}

export function setPaginationObject(pagination: IPagination) {
    return {
        type: ActionType.SetPaginationObject,
        value: pagination
    }
}