import axios from 'axios';
import * as React from 'react';
import { IDynamicContentPages, IGeoLocationCoordinates, ILocationFilterState } from '../../classes/dynamic-listing';
import { DynamicContentContainerViewProps } from '../../components/container';

interface ICurrentFilter {
    label: string;
    buttonText: string;
    placeholder: string;
    container: DynamicContentContainerViewProps;
    locationState: ILocationFilterState;
    errorMessage: string;
    value: string;
}


const DynamicLocationFilterContainerView: React.FunctionComponent<ICurrentFilter> = props => {
    const [message, setMessage] = React.useState(""); 
    const [address, setAddress] = React.useState(""); 
    const [zipCode, setZipCode] = React.useState(""); 
    const [latitude, setLatitude] = React.useState("0"); 
    const [longitude, setLongitude] = React.useState("0"); 
    const [gpsAddress, setGpsAddress] = React.useState("");
    const [gpsLatitude, setGpsLatitude] = React.useState("0");
    const [gpsLongitude, setGpsLongitude] = React.useState("0"); 
    const _inputValueRef = React.useRef(null);
    const zipCodeRegex = new RegExp(/^\d{5}(?:[-\s]\d{4})?$/);

    async function fetchData(address: string, zipCode: string) {
        const response = await axios.get(`/ach-api/dynamiclisting/GetCoordinatesFromAddressOrZipCode?address=${address}&zipCode=${zipCode}`);

        if (response && response.status == 200) {
            setLatitude(response.data.Latitude.toString());
            setLongitude(response.data.Longitude.toString());
        }

        getAddressOrZipCodeCoordinates(response.data.Latitude.toString(), response.data.Longitude.toString());
    }

    function onChange(e) {
        setMessage(e.target.value);
    }

    async function updateSearchValue(e) {
        props.container.resetLocationState();
        var locationState: ILocationFilterState = props.container.locationState;
        locationState.foundAddress = e.current.value;
        props.container.updateLocationState(locationState);
        setMessage(e.current.value);

        // Only call the GetCoordinatesFromAddressOrZipCode API when the user has input a new address or zip code
        if (e.current.value.match(zipCodeRegex)) {
            if (address != "" || zipCode != e.current.value) {
                setAddress("");
                setZipCode(e.current.value);

                await fetchData("", e.current.value);
            }
            else {
                getAddressOrZipCodeCoordinates(latitude, longitude);
            }
        }
        else {
            if (address != e.current.value || zipCode != "") {
                setAddress(e.current.value);
                setZipCode("");

                await fetchData(e.current.value, "");
            }
            else {
                getAddressOrZipCodeCoordinates(latitude, longitude);
            }
        }
    }

    function getAddressOrZipCodeCoordinates(latitude: string, longitude: string) {
        var showError = false;
        var newLocationState: ILocationFilterState = props.container.locationState;

        try {        
            var coordinates: IGeoLocationCoordinates = { latitude: Number(latitude), longitude: Number(longitude) };
            if (coordinates) {
                if (coordinates.latitude != 0 && coordinates.longitude != 0) {
                    newLocationState.latitude = coordinates.latitude;
                    newLocationState.longitude = coordinates.longitude;
                    props.container.updateLocationState(newLocationState);

                    sortLocations(props.container.locationState);
                }
            } else {
                showError = true;
            }      
        } catch (e) {
            showError = true;
            console.error(e);
        }

        if (showError) {
            newLocationState.showError = true;
            props.container.updateLocationState(newLocationState);
        }
    }

    async function sortLocations(locationState: ILocationFilterState) {
        try {
            const sortedLocations = await axios.post(`/ach-api/dynamiclisting/sortByLocation`, locationState);
            if (sortedLocations.status == 200 && sortedLocations.data) {
                var contentPages: Array<IDynamicContentPages> = [];
                var filteredContentPages: Array<IDynamicContentPages> = [];
                sortedLocations.data.forEach(pg => {
                    var locations: Array<IGeoLocationCoordinates> = [];
                    pg.LocationCoordinates.forEach(coord => {
                        var locationCoordinates: IGeoLocationCoordinates = { latitude: coord.Latitude, longitude: coord.Longitude };
                        locations.push(locationCoordinates);
                    });
                    var dynamicContent: IDynamicContentPages = {
                        firstFilter: pg.FirstFilter,
                        secondFilter: pg.SecondFilter,
                        thirdFilter: pg.ThirdFilter,
                        title: pg.Title,
                        imageUrl: pg.ImageUrl,
                        dynamicContentPageUrl: pg.DynamicContentPageUrl,
                        description: pg.Description,
                        isDoctorPage: pg.IsDoctorPage,
                        hideFirstFilterContent: pg.HideFirstFilterContent,
                        hideSecondFilterContent: pg.HideSecondFilterContent,
                        hideThirdFilterContent: pg.HideThirdFilterContent,
                        locationCoordinates: locations,
                        distanceFrom: pg.DistanceFrom
                    }
                    contentPages.push(dynamicContent);
                });
                contentPages.forEach(pg => {
                    if (props.container.filteredDynamicContentPages.includes(pg)) 
                        filteredContentPages.push(pg);
                });
                locationState.dynamicContentPages = contentPages;
                locationState.filteredDynamicContentPages = filteredContentPages;
                props.container.updateLocationState(locationState);
                props.container.updatePagesPostLocationFilter(contentPages);
            }
        } catch (e) {
            locationState.showError = true;
            props.container.updateLocationState(locationState);
            console.error(e);
        }
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter') {
            updateSearchValue(_inputValueRef);
        }
    }

    function handleSubmitButton(e) {
        updateSearchValue(_inputValueRef);
    }

    function useMyLocationClick(e) {
        props.container.resetLocationState();
       
        if (navigator.geolocation) {
            // Only call the GetAddressReverseGeocode API when the Geolocation API returns new coordinates
            navigator.geolocation.getCurrentPosition(async (position) => {
                if (gpsLatitude != position.coords.latitude.toString() || gpsLongitude != position.coords.longitude.toString()) {
                    setGpsLatitude(position.coords.latitude.toString());
                    setGpsLongitude(position.coords.longitude.toString());

                    await fetchCurrentLocation(position.coords.latitude.toString(), position.coords.longitude.toString());
                }
                else {
                    currentLocationHandler(gpsLatitude, gpsLongitude, gpsAddress, false);
                }

            }, currentLocationErrorHandler.bind(this));
        }
        else {
            var locationState: ILocationFilterState = props.container.locationState;
            locationState.showError = true;
            props.container.updateLocationState(locationState);
        }
    }

    async function fetchCurrentLocation(latitude: string, longitude: string) {
        const response = await axios.get(`/ach-api/dynamiclisting/GetAddressReverseGeocode?latitude=${latitude}&longitude=${longitude}`);

        if (response && response.status == 200 && response.data && response.data.foundAddress) {
            setGpsAddress(response.data.foundAddress);

            currentLocationHandler(latitude, longitude, response.data.foundAddress, false);
        }
        else {
            currentLocationHandler(latitude, longitude, gpsAddress, true);
        }
    }

    async function currentLocationHandler(latitude: string, longitude: string, address: string, showError: boolean) {
        var newLocationState: ILocationFilterState = props.container.locationState;

        try {
            if (!showError) {
                const foundAddress = address;
                console.log(`found Address: ${foundAddress}`);

                newLocationState.foundAddress = address;
                newLocationState.latitude = Number(latitude);
                newLocationState.longitude = Number(longitude);
                props.container.updateLocationState(newLocationState);

                sortLocations(props.container.locationState);
            }
        } catch (e) {
            showError = true;
            console.error(e);
        }

        if (showError) {
            newLocationState.showError = true;
            props.container.updateLocationState(newLocationState);
        }
    }

    function currentLocationErrorHandler(error) {
        switch (error.code) {
            case error.PERMISSION_DENIED:
                console.error('User denied the request for Geolocation.');
                break;
            case error.POSITION_UNAVAILABLE:
                console.error('Location information is unavailable.');
                break;
            case error.TIMEOUT:
                console.error('The request to get user location timed out.');
                break;
            case error.UNKNOWN_ERROR:
                console.error('An unknown error occurred.');
                break;
        }
        var newLocationState: ILocationFilterState = {
            latitude: 0.0,
            longitude: 0.0,
            dynamicContentPages: props.container.dynamicContentPages,
            filteredDynamicContentPages: props.container.filteredDynamicContentPages,
            showError: true,
            foundAddress: null
        };
        
        props.container.updateLocationState(newLocationState);
    }

    return (
        <li className="stack-sm listing-filters-item">
            <label htmlFor="AddressOrCityOrZip">
                {props.label}
            </label>
            <div className="listing-location">
                <input ref={_inputValueRef} id="AddressOrCityOrZip" type="text" placeholder={props.placeholder} onChange={onChange} onKeyPress={handleKeyPress} value={message} />
                <button className="btn-primary" id="UseLocation" type="button" onClick={useMyLocationClick}>
                    {props.container.text.useMyLocationButtonText}
                </button>
                <button id="LocationButton" type="button" onClick={handleSubmitButton} className="btn">{props.buttonText}</button>
            </div>
            <p className={props.locationState.showError ? "" : "hide"}>{props.errorMessage}</p>
        </li>

    )
}

export default DynamicLocationFilterContainerView;