import * as React from 'react';
import { DynamicContentContainerViewProps } from '../../components/container';
import DynamicContentListingAllFiltersView from '../library/dynamic-all-filters.view';
// @ts-ignore  
import { format } from 'react-string-format';

const DynamicContentListingContainerView: React.FunctionComponent<DynamicContentContainerViewProps> = props => {
    return (
        <div className="stack-lg">
            <div className="stack-xl dynamic-listing-filters">
                <div className="stack">
                    <h2 className="h3-style">
                        {props.text.filterHeading}
                    </h2>
                    <DynamicContentListingAllFiltersView {...props} />
                </div>
            </div>
            <div>
                {
                    
                    (props.filteredDynamicContentPages.length > 0) ?
                        (
                        <ul className="listing-grid listing-grid-4">
                            {
                                props.filteredDynamicContentPages.slice(props.firstIndex, props.lastIndex).map((page, index) =>
                                    <li className="listing-grid-item stack-sm" key={index}>
                                        <div className="stack-sm">
                                            {
                                                page.imageUrl !== "" ?
                                                    <div className={`listing-grid-image ${page.isDoctorPage ? "listing-grid-image-portrait" : ""}`}>
                                                        <img src={page.imageUrl} alt="" role="presentation" />

                                                        {
                                                            page.firstFilter != null && page.firstFilter.length > 0 && !page.hideFirstFilterContent ?
                                                                <ul className="location-doctor-specialties">
                                                                    {page.firstFilter.map((filter, index) =>
                                                                        <li key={index} className={filter.replace(/ /g, "-")} >
                                                                            {filter}
                                                                        </li>
                                                                    )}
                                                                </ul>
                                                                :
                                                                null
                                                        }
                                                    </div>
                                                    :
                                                    null
                                            }
                                            {
                                                page.title !== "" &&
                                                <h3 className="h5-style">
                                                    {page.title}
                                                </h3>
                                            }
                                            {
                                                page.description?.length > 0 ?
                                                    page.description.map((desc, index) =>
                                                        <div key={index} >
                                                            <p className="small">{desc}</p>
                                                        </div>
                                                    )
                                                    :
                                                    null
                                            }
                                            {
                                                props.locationState.foundAddress !== "" && props.text.distanceMessaging && page.distanceFrom > 0 &&
                                                <div>
                                                    <p className="small">{format(props.text.distanceMessaging, page.distanceFrom)} </p>
                                                </div>
                                            }
                                            {
                                                page.firstFilter != null && page.firstFilter.length > 0 && !page.hideFirstFilterContent ?
                                                    <p className="small bold first-filter">
                                                        {page.firstFilter.join(", ")}
                                                    </p>
                                                    :
                                                    null
                                            }
                                            {
                                                page.secondFilter != null && page.secondFilter.length > 0 && !page.hideSecondFilterContent ?
                                                    <p className="small bold second-filter">
                                                        {page.secondFilter.join(", ")}
                                                    </p>
                                                    :
                                                    null
                                            }
                                            {
                                                page.thirdFilter != null && page.thirdFilter.length > 0 && !page.hideThirdFilterContent ?
                                                    <p className="small bold third-filter">
                                                        {page.thirdFilter.join(", ")}
                                                    </p>
                                                    :
                                                    null
                                            }
                                        </div>
                                        <div className="stack-sm">
                                            {
                                                props.text.appointmentButtonText !== "" && props.text.appointmentButtonPageUrl !== "" &&
                                                <p><a className="btn-primary btn-full" href={props.text.appointmentButtonPageUrl}>{props.text.appointmentButtonText}</a></p>
                                            }
                                            {
                                                props.text.learnMoreButtonText !== "" && page.dynamicContentPageUrl !== "" &&
                                                <p><a className="btn-secondary btn-full" href={page.dynamicContentPageUrl}>{props.text.learnMoreButtonText}</a></p>
                                            }
                                        </div>
                                    </li>
                                )
                            }
                        </ul>
                    ) :
                    (
                        <section className="no-results-messaging">
                            <h2>{props.text.noResultsMessage}</h2>
                        </section>
                    )
                }
            </div>
        </div>
    )
}

export default DynamicContentListingContainerView;