import { IDynamicContentText, defaultDynamicContentText } from "../../classes/dynamic-listing";
import { IDynamicContentStateAction, ActionType } from "../../classes/dynamic-listing-actions";


/**
 * Returns the new dynamic content listing state given a particular action. This where all of the application
 * logic resides.
 */
export default function dynamicContentTextReducer(state: IDynamicContentText = defaultDynamicContentText, action: IDynamicContentStateAction) {
    switch (action.type) {
        case ActionType.SetDynamicContentTextObject: {
            return { ...state, ...action.value } as IDynamicContentText;
        }
        default:
            return state;
    }
}
