import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { logger } from '../../common/helpers/middleware';
import { IDynamicContentText, IDynamicContentState } from '../classes/dynamic-listing';
import dynamicContentReducer from './reducers/dynamic.reducer';
import dynamicContentTextReducer from './reducers/dynamic-text.reducer';
import { setFilterObject, setDynamicContentObject, setDynamicContentTextObject } from './actions/dynamic.actions';
import { setPaginationObject } from './actions/dynamic-pagination.actions';
import { updateLocationState } from './actions/dynamic-filter.actions';

export interface IDynamicContentAppState {
    dynamicContentText: IDynamicContentText,
    dynamicContentState: IDynamicContentState,
    filtersOpen: boolean;
    toggleFiltersOpen: () => void;
}

const appReducer = combineReducers({
    dynamicContentText: dynamicContentTextReducer,
    dynamicContentState: dynamicContentReducer
});

const appStore = createStore(appReducer, {}, applyMiddleware(thunk, logger));
appStore.dispatch(setFilterObject((window as any).dynamicContentFilters) as any);
appStore.dispatch(setDynamicContentObject((window as any).dynamicContentPages) as any);
appStore.dispatch(setDynamicContentTextObject((window as any).pageData) as any);
appStore.dispatch(setPaginationObject((window as any).pagination) as any);
appStore.dispatch(updateLocationState((window as any).locationFilter) as any);

const setAppBlockStore = (storeId: string) => {
    const appBlockStore = createStore(appReducer, {}, applyMiddleware(thunk, logger));
    const blockData = (window as any)[`blockData_${storeId}`];
    appBlockStore.dispatch(setFilterObject((blockData as any).dynamicContentFilters) as any);
    appBlockStore.dispatch(setDynamicContentObject((blockData as any).dynamicContentPages) as any);
    appBlockStore.dispatch(setDynamicContentTextObject((blockData as any).pageData) as any);
    appBlockStore.dispatch(setPaginationObject((blockData as any).pagination) as any);
    appBlockStore.dispatch(updateLocationState((blockData as any).locationFilter) as any);
    return appBlockStore;
}

export { appStore, setAppBlockStore };