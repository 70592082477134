//import * as ga from 'react-google-analytics';
//import * as ReactGA from 'react-ga';
import { ComponentClass, ClassAttributes } from "react";
import { getParameterByName } from "./query-string-helper";

export interface IndexRouteProps<Props> {
}

function getParameterFromUrl(name: string, url: string) {
    name = name.replace(/[\[\]]/g, "\\$&");
    var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

type IndexRoute = ComponentClass<IndexRouteProps<any>>;
export const logger = store => next => action => {
    //console.log('dispatching', { actionType: ActionType[action.type], payload: action.value })
    let result = next(action)

    var shouldLog = getParameterByName('enableLogging');
    if (shouldLog !== null) {
        console.log("SMARTSEAT_ACTION", "", { type: action.type, value: action.value, result: getParameterByName('logState') != null ? result : "add 'logState' to query string to log" });
    }

    //if (action.type === ActionTypes.TRACK_RESERVE_VALUE) {
    //    (ReactGA as any).ga('send', 'event', 'Smart Seat Actions', ActionTypes[action.type], '', action.value);
    //    (ReactGA as any).ga('adageTracker.send', 'event', window.location.hostname + ':Smart Seat Actions', ActionTypes[action.type], '', action.value);
    //}
    //else if (action.type in GaActionType) {
    //    if (action.unTrackEvent !== undefined && action.unTrackEvent) {
    //        //don't track this event in GA
    //    } else {
    //        (ReactGA as any).ga('send', 'event', 'Smart Seat Actions', ActionTypes[action.type]);
    //        (ReactGA as any).ga('adageTracker.send', 'event', window.location.hostname + ':Smart Seat Actions', ActionTypes[action.type]);
    //    }
    //}

    return result
}

