import { IDynamicContentState, defaultDynamicContentState, IPagination, IFilterRequest, IDynamicContentPages, defaultFilterRequest, defaultPagination, defaultFilterNames, ILocationFilterState, defaultLocationFilterRequest } from "../../classes/dynamic-listing";
import { IDynamicContentStateAction, ActionType } from "../../classes/dynamic-listing-actions";
// @ts-ignore  
import { format } from 'react-string-format';



/**
 * Returns the new dynamic content listing state given a particular action. This where all of the application
 * logic resides.
 */
export default function dynamicContentReducer(state: IDynamicContentState = defaultDynamicContentState, action: IDynamicContentStateAction) {
    switch (action.type) {
        case ActionType.SetFilterRequest: {
            return { ...state, request: action.value } as IDynamicContentState;
        }
        case ActionType.SetDynamicContentObject: {
            return { ...state, filteredDynamicContentPages: action.value as Array<IDynamicContentPages>, allDynamicContentPages: action.value as Array<IDynamicContentPages> } as IDynamicContentState;
        }
        case ActionType.SetFilterObject: {
            var initialRequest: IFilterRequest = {
                selectedFirstFilter: defaultFilterRequest.selectedFirstFilter,
                selectedSecondFilter: defaultFilterRequest.selectedSecondFilter,
                selectedThirdFilter: defaultFilterRequest.selectedThirdFilter,
                filterId: defaultFilterRequest.filterId,
                newSelectedValue: defaultFilterRequest.newSelectedValue
            };
            return { ...state, dynamicContentFilters: action.value, request: initialRequest } as IDynamicContentState;
        }
        case ActionType.SetPaginationObject: {
            return { ...state, pagination: action.value } as IDynamicContentState;
        }
        case ActionType.RemoveAllFilters: {
            var resultsOnPage = state.pagination.maximumNumOfPages <= state.allDynamicContentPages.length ? state.pagination.maximumNumOfPages : state.allDynamicContentPages.length;
            var newPagination: IPagination = {
                currentPage: 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: state.allDynamicContentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, state.allDynamicContentPages.length > 0 ? 1 : 0, resultsOnPage, state.allDynamicContentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: resultsOnPage
            };
            var newLocationFilterState: ILocationFilterState = defaultLocationFilterRequest;
            newLocationFilterState.dynamicContentPages = state.allDynamicContentPages;
            newLocationFilterState.filteredDynamicContentPages = state.allDynamicContentPages;
            newLocationFilterState.foundAddress = "";

            return { ...state, request: defaultFilterRequest, filteredDynamicContentPages: state.allDynamicContentPages, pagination: newPagination, locationFilterState: newLocationFilterState } as IDynamicContentState;
        }
        case ActionType.UpdateFilterRequest: {
            var newFilterRequest: IFilterRequest = action.value;
            if (newFilterRequest.filterId == defaultFilterNames.firstFilterName)
                newFilterRequest.selectedFirstFilter = newFilterRequest.newSelectedValue;
            else if (newFilterRequest.filterId == defaultFilterNames.secondFilterName)
                newFilterRequest.selectedSecondFilter = newFilterRequest.newSelectedValue;
            else if (newFilterRequest.filterId == defaultFilterNames.thirdFilterName)
                newFilterRequest.selectedThirdFilter = newFilterRequest.newSelectedValue;

            var contentPages = state.allDynamicContentPages.sort((a, b) => a.distanceFrom - b.distanceFrom);

            var firstFilterList = [];
            var secondFilterList = [];
            var thirdFilterList = [];
            if (newFilterRequest.selectedFirstFilter !== defaultFilterRequest.selectedFirstFilter ) {
                contentPages.forEach(pg => {
                    if (pg.firstFilter.includes(newFilterRequest.selectedFirstFilter))
                        firstFilterList.push(pg);
                })
            }
            if (newFilterRequest.selectedSecondFilter !== defaultFilterRequest.selectedSecondFilter) {
                contentPages.forEach(pg => {
                    if (pg.secondFilter.includes(newFilterRequest.selectedSecondFilter))
                        secondFilterList.push(pg);
                })
            }
            if (newFilterRequest.selectedThirdFilter !== defaultFilterRequest.selectedThirdFilter) {
                contentPages.forEach(pg => {
                    if (pg.thirdFilter.includes(newFilterRequest.selectedThirdFilter))
                        thirdFilterList.push(pg);
                })
            }

            var data = [];
            if (firstFilterList.length > 0)
                data.push(firstFilterList);
            if (secondFilterList.length > 0)
                data.push(secondFilterList);
            if (thirdFilterList.length > 0)
                data.push(thirdFilterList);

            var updatedList = [];
            if (data.length > 0)
                updatedList = data.reduce((a, b) => a.filter(c => b.includes(c)));
            else
                updatedList = contentPages;

            var resultsOnPage = state.pagination.maximumNumOfPages <= updatedList.length ? state.pagination.maximumNumOfPages : updatedList.length;
            var newPagination: IPagination = {
                currentPage: 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: updatedList.length,
                resultsMessage:  state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, updatedList.length > 0 ? 1 : 0, resultsOnPage, updatedList.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: resultsOnPage
            };
            var newLocationFilterState: ILocationFilterState = state.locationFilterState;
            newLocationFilterState.dynamicContentPages = updatedList;

            return { ...state, filteredDynamicContentPages: updatedList, request: newFilterRequest, pagination: newPagination, locationFilterState: newLocationFilterState } as IDynamicContentState;
        }
        case ActionType.NextPage: {
            var lastIndex = (state.pagination.currentPage + 1) * state.pagination.maximumNumOfPages;
            var firstIndex = lastIndex - state.pagination.maximumNumOfPages;
            if (lastIndex > state.filteredDynamicContentPages.length)
                lastIndex = state.filteredDynamicContentPages.length;
            var newPagination: IPagination = {
                currentPage: state.pagination.currentPage + 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: state.filteredDynamicContentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, firstIndex + 1, lastIndex, state.filteredDynamicContentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: lastIndex
            };
            return { ...state, pagination: newPagination } as IDynamicContentState;
        }
        case ActionType.PreviousPage: {
            var lastIndex = (state.pagination.currentPage - 1) * state.pagination.maximumNumOfPages;
            var firstIndex = lastIndex - state.pagination.maximumNumOfPages;
            var newPagination: IPagination = {
                currentPage: state.pagination.currentPage - 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: state.filteredDynamicContentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, firstIndex + 1, lastIndex, state.filteredDynamicContentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: lastIndex
            };
            return { ...state, pagination: newPagination } as IDynamicContentState;
        }
        case ActionType.LoadMore: {
            var lastIndex = state.pagination.resultsToShow + state.pagination.maximumNumOfPages <= state.pagination.totalResults ?
                state.pagination.resultsToShow + state.pagination.maximumNumOfPages : state.filteredDynamicContentPages.length;
            var newPagination: IPagination = {
                currentPage: state.pagination.currentPage + 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: state.filteredDynamicContentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, 1, lastIndex, state.filteredDynamicContentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: lastIndex
            };
            return { ...state, pagination: newPagination } as IDynamicContentState;
        }
        case ActionType.GoToPage: {
            var lastIndex = parseInt(action.value) * state.pagination.maximumNumOfPages;
            var firstIndex = lastIndex - state.pagination.maximumNumOfPages;
            if (lastIndex > state.filteredDynamicContentPages.length)
                lastIndex = state.filteredDynamicContentPages.length;
            var newPagination: IPagination = {
                currentPage: parseInt(action.value),
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: state.filteredDynamicContentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, firstIndex + 1, lastIndex, state.filteredDynamicContentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: lastIndex
            };
            return { ...state, pagination: newPagination } as IDynamicContentState;
        }
        case ActionType.UpdatePagesPostLocationFilter: {
            var newLocationState: ILocationFilterState = state.locationFilterState;
            var contentPages = newLocationState.dynamicContentPages;

            var resultsOnPage = state.pagination.maximumNumOfPages <= contentPages.length ? state.pagination.maximumNumOfPages : contentPages.length;
            var newPagination: IPagination = {
                currentPage: 1,
                maximumNumOfPages: state.pagination.maximumNumOfPages,
                totalResults: contentPages.length,
                resultsMessage: state.pagination.resultsMessage,
                populatedResultsMessage: format(state.pagination.resultsMessage, contentPages.length > 0 ? 1 : 0, resultsOnPage, contentPages.length),
                hidePagination: state.pagination.hidePagination,
                resultsToShow: resultsOnPage
            };
            return { ...state, locationFilterState: newLocationState, allDynamicContentPages: newLocationState.dynamicContentPages, filteredDynamicContentPages: contentPages, pagination: newPagination } as IDynamicContentState
        }
        case ActionType.UpdateLocationState: {
            return { ...state, locationFilterState: action.value as ILocationFilterState } as IDynamicContentState
        }
        case ActionType.ResetLocationState: {
            var resetLocationState: ILocationFilterState = defaultLocationFilterRequest;
            resetLocationState.filteredDynamicContentPages = state.filteredDynamicContentPages;
            resetLocationState.dynamicContentPages = state.allDynamicContentPages;
            return { ...state, locationFilterState: resetLocationState } as IDynamicContentState
        }
        default:
            return state;
    }
}