import * as React from 'react';
import { AppProps } from '../../components/app';
import DynamicContentBaseContainer from '../../components/container';
import DynamicContentPaginationBaseContainer from '../../components/pagination';


const AppView: React.FunctionComponent<AppProps> = props => {
    return (
        <div className="block listing theme-light">
            <div className="block-inner">
                <DynamicContentBaseContainer />
                <DynamicContentPaginationBaseContainer />
            </div>
        </div>
    );
}

export default AppView;